<template>
  <div class="public-law-page">
    <div v-show="isShow">
      <law-detail :entry="lawEntry" @onBack="onBack" />
    </div>
    <!-- <div class="app-download-tip">
      <download-app />
    </div> -->
  </div>
</template>

<script>
import LawDetail from './index'

export default {
  name: 'public-law-page',
  data() {
    return {
      lawEntry: {},
      isShow: false,
    }
  },
  components: {
    LawDetail,
  },
  computed: {},
  methods: {
    onBack(value) {
      this.isShow = value
      this.$router.push('/search')
    },
  },
  mounted() {},
  created() {
    const { id } = this.$route.params
    const { toCatalog } = this.$route.query
    const entry = {}
    if (id) {
      entry.id = id
    }
    if (toCatalog) {
      entry.catalog = toCatalog
    }
    this.isShow = true
    this.lawEntry = entry
  },
}
</script>

<style scoped lang="stylus">
.public-law-page
  >>> .nav-bar .van-nav-bar__right
    display none
  >>> .law-scroller .mode-text
    display none
  & .app-download-tip
    position fixed
    bottom 0
    left 0
    width 100%
</style>
