var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "public-law-page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow,
            expression: "isShow",
          },
        ],
      },
      [
        _c("law-detail", {
          attrs: { entry: _vm.lawEntry },
          on: { onBack: _vm.onBack },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }